import { GatsbyImage, getImage } from "gatsby-plugin-image";
import React, { useContext } from "react";
import { useState } from "react";
import { Colors } from "../../../commons/Theme";
import { DataContext } from "../../../contexts/DataContext";
import useScrollController from "../../../hooks/useScrollController";
import Icon, { icons } from "../../Icon/Icon";
import { 
  StyledIntro, 
  StyledIntroDown, 
  StyledIntroImage, 
  StyledIntroOverlay, 
  StyledIntroPlay,
  StyledIntroTextContainer,
  StyledIntroTitle,
  StyledIntroVideoContainer,
  StyledSubtitle,
  StyledTitle,
} from "../style";
import Newsletter from "./Newsletter";

const Intro = () => {
  const { pageLabels, pageImages } = useContext(DataContext);
  const labels = pageLabels.intro;
  const imageData = pageImages.find(e => e.path === "/2020/images/the-great-meltdown/background.png");

  const [isPlay, setIsPlay] = useState(false);
  const { scrollToCssSelector } = useScrollController();

  return (
    <StyledIntro>
      {isPlay ? (
        <StyledIntroTextContainer>
          <StyledTitle  dangerouslySetInnerHTML={{ __html: labels.title }}/>
          <StyledSubtitle dangerouslySetInnerHTML={{ __html: labels.subtitle }}/>
          <Newsletter />
          <StyledIntroDown onClick={() => scrollToCssSelector("#infos", 71)}>
            <Icon icon={icons.chevronDown} color={Colors.yellow} />
          </StyledIntroDown>
        </StyledIntroTextContainer>
      ) : (
        <StyledIntroVideoContainer onClick={() => setIsPlay(true)}>
          <StyledIntroImage>
            <GatsbyImage style={{ height: "inherit" }} objectFit="contain" image={getImage(imageData)} alt={imageData.alt} />
            <StyledIntroOverlay />
            <StyledIntroPlay>
              <Icon icon={icons.play2} color={Colors.white} />
            </StyledIntroPlay>
          </StyledIntroImage>
          <StyledIntroTitle>
            <p style={{ opacity: 0.5 }}>{labels.watching}</p>
            <StyledTitle as="h1" style={{ paddingBottom: 0, textAlign: "left" }}>{labels.film}</StyledTitle>
            <StyledSubtitle style={{ textAlign: "left", paddingBottom: "0.5rem" }}>{labels.season}</StyledSubtitle>
            <h2 style={{ opacity: 0.5, fontSize: 16, paddingBottom: "2rem" }}>{labels.description}</h2>
          </StyledIntroTitle>
        </StyledIntroVideoContainer>
      )}
    </StyledIntro>
  );
};

export default Intro;
